import Grid from '@react-css/grid';
import { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import EditShopifySettings from 'containers/SettingsPage/DropshippingTab/IntegrationsSettings/Shopify/EditShopifySettings';
import EditStoreNameButton from 'containers/SettingsPage/DropshippingTab/IntegrationsSettings/Shopify/EditStoreNameButton';
import useAlertQueue from 'hooks/useAlertQueue';
import { useCanEditStoreName } from 'hooks/useCanEditStoreName';
import { isEmpty } from 'lodash';
import { selectCompany } from 'store/selectors/me/company';
import { updateShopifyAuth } from 'store/slices/company';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import { getShopifyAuthUrl, getShopifyStore } from 'utils/platform';
import EditShareCustomerSettings from './EditShareCustomerSettings';

interface ConnectFormProps {
  updatedStore: string;
  updatedToken: string;
}

const Connect = () => {
  const dispatch = useDispatch();
  const { addSuccessAlert, addErrorAlert } = useAlertQueue();

  const company = useSelector(selectCompany);
  const store = getShopifyStore(company);

  const [shopifyAuthUrl, setShopifyAuthUrl] = useState<string>(getShopifyAuthUrl(store));
  const [overrideLongPress, setOverrideLongPress] = useState<boolean | undefined>(undefined);
  const [overrideEnabled, setOverrideEnabled] = useState<boolean | undefined>(undefined);
  const [preventOpenTab, setPreventOpenTab] = useState<boolean>(false);
  const [isEditingAccessToken, setIsEditingAccessToken] = useState<boolean>(false);
  const [isEditingStoreURL, setIsEditingStoreURL] = useState<boolean>(false);

  const [isStoreNameEditable] = useCanEditStoreName();

  const isShopifyConnected = company?.productPlatformActive;
  const isMissingUrl = !isStoreNameEditable && !store;

  const onCancel = () => {
    setIsEditingAccessToken(false);
    setIsEditingStoreURL(false);
    setOverrideEnabled(false);
  };

  const buildUpdates = ({ updatedStore, updatedToken }: ConnectFormProps) => {
    if (!isEmpty(updatedStore) && !isEmpty(updatedToken)) {
      return {
        shopifyAuth: {
          platform: 'shopify',
          store: updatedStore,
          accessToken: updatedToken,
        },
      };
    }
    if (!isEmpty(updatedStore)) {
      return {
        shopifyAuth: {
          platform: 'shopify',
          store: updatedStore,
        },
      };
    }
    return {
      shopifyAuth: {
        platform: 'shopify',
        accessToken: updatedToken,
      },
    };
  };

  const onSubmit = ({ updatedStore, updatedToken }: ConnectFormProps) =>
    dispatch(updateShopifyAuth(buildUpdates({ updatedStore, updatedToken }))).then(
      (action: { error?: { message: string } }) => {
        if (action.error) {
          addErrorAlert('Something went wrong', action.error.message);
          return;
        }
        addSuccessAlert('Success', 'Updated Shopify Settings');
        if (updatedStore) {
          setShopifyAuthUrl(getShopifyAuthUrl(updatedStore));
        }
        setIsEditingAccessToken(false);
        setOverrideEnabled(false);
        setIsEditingStoreURL(false);
      }
    );

  const openShopifyAuthTab = () => {
    if (!preventOpenTab && overrideLongPress !== undefined) {
      window.open(shopifyAuthUrl, '_blank', 'noopener,noreferrer');
    }
    setPreventOpenTab(false);
  };

  useEffect(() => {
    // This effect is used to track how long a user has been
    // pressing down on the Connect button. This is then used to unlock
    // API Access Token override if the button is preseed for 5 seconds.
    let overrideTimer: NodeJS.Timeout | undefined;
    if (overrideLongPress) {
      // If it's a press down then we start a timer
      overrideTimer = setTimeout(() => {
        setOverrideEnabled(true);
        // we prevent the default of the button in this case since the
        // intent was to unlock the hidden button and not to open a new tab
        setPreventOpenTab(true);
      }, 5000);
    } else {
      // If the press is released we clear the timer. This essentially means that
      // unless it's interrupted the above timer function will trigger.
      clearTimeout(overrideTimer);
    }
    return () => {
      clearTimeout(overrideTimer);
    };
  }, [overrideLongPress, shopifyAuthUrl, preventOpenTab]);

  // Reset all the state variables on destroy
  useEffect(
    () => () => {
      setOverrideLongPress(undefined);
      setOverrideEnabled(undefined);
      setPreventOpenTab(false);
    },
    []
  );

  return (
    <>
      <hr className="my-4" />
      <h4>
        Connect your Shopify Store
        {isShopifyConnected && (
          <Badge variant="success" pill className="ml-3 mt-n1">
            Connected
          </Badge>
        )}
      </h4>

      {!isShopifyConnected && (
        <p className="small text-muted mb-1">
          Please connect the Modern Dropship Shopify app to your store.
        </p>
      )}

      {isMissingUrl ? (
        <p className="small text-muted">
          <strong>Your Shopify Store URL is missing! Please contact support.</strong>
        </p>
      ) : (
        <p className="small text-muted">
          Store: <strong>{`https://${store}.myshopify.com`}</strong>
        </p>
      )}

      <Grid gap="8px" alignItemsStart justifyContentStart columns="1fr 1fr">
        <SecondaryButton
          size="small"
          onClick={() => openShopifyAuthTab()}
          onMouseDown={() => setOverrideLongPress(true)}
          onMouseUp={() => setOverrideLongPress(true)}
          onMouseLeave={() => setOverrideLongPress(false)}
          onTouchStart={() => setOverrideLongPress(true)}
          onTouchEnd={() => setOverrideLongPress(false)}
          disabled={!store || isEditingStoreURL || isEditingAccessToken}
          kind={overrideEnabled ? 'neutral' : 'action'}
        >
          {isShopifyConnected ? 'Re-Connect Shopify' : 'Connect Shopify'}
        </SecondaryButton>

        {overrideEnabled && (
          <SecondaryButton
            kind="destructive"
            size="small"
            onClick={() => setIsEditingAccessToken(true)}
          >
            Override API Token
          </SecondaryButton>
        )}

        {!overrideEnabled && !isEditingStoreURL && (
          <EditStoreNameButton onClick={() => setIsEditingStoreURL(true)} />
        )}
      </Grid>

      {(isEditingAccessToken || isEditingStoreURL) && (
        <EditShopifySettings
          isEditable={isStoreNameEditable}
          isEditingAccessToken={isEditingAccessToken}
          onSubmit={onSubmit}
          onCancel={onCancel}
          store={store}
        />
      )}

      <hr className="my-4" />
      <EditShareCustomerSettings />
    </>
  );
};

export default Connect;
