import type { BaseQueryParams, Response } from 'types/api';
import type { Address, DeepPartial, MerchandisingSettings, PaymentTerms } from 'types/general';
import type {
  CommerceType,
  CompanyEDIData,
  CompanyFlags,
  CompanyGettingStarted,
  CompanyPrivacy,
  CompanyResponse,
  CompanyReturnsSettings,
  CompanySFTPSettings,
  CompanyShopifySettings,
  Platform,
} from 'types/models/company';
import type { ShippingSettings } from 'types/models/shipping';
import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from 'utils/request';

interface GetCompaniesParams extends BaseQueryParams {
  search: string;
}

type GetCompaniesResponse = CompanyResponse[];

export const getCompanies = (params: GetCompaniesParams): Promise<GetCompaniesResponse> =>
  getRequest('/companies/', params);

type GetCompanyResponse = CompanyResponse;

export const getCompany = (companyId: string): Promise<GetCompanyResponse> =>
  getRequest(`/companies/${companyId}`);

type CompanyAuthUpdate = DeepPartial<{
  removeShopify: boolean;
  removeBigCommerce: boolean;
  removeWooCommerce: boolean;
  removeMagento2: boolean;
  wooCommerceAuth: {
    platform: Platform;
    platformUrl: string;
    wooCommerceKey: string;
    wooCommerceSecret: string;
  };
  bigCommerceAuth: {
    platform: Platform;
    platformUrl: string;
    bigCommerceClientId: string;
    bigCommerceAccessToken: string;
    bigCommerceHash: string;
  };
  magento2Auth: {
    platform: Platform;
    platformUrl: string;
    consumerKey: string;
    consumerSecret: string;
    accessToken: string;
    accessTokenSecret: string;
    storeView: string;
  };
  shopifyAuth: {
    platform: Platform;
    platformUrl: string;
    accessToken: string;
  };
}>;

export type CompanyFlagsUpdate = Pick<
  CompanyFlags,
  | 'skipBuyerNotifications'
  | 'ignoreInventoryType'
  | 'overwriteComparedAtPrice'
  | 'useRetailPriceForComparedAt'
  | 'pushProductsAsTaxable'
  | 'syncUnpublishedProducts'
>;

export type UpdateCompanyParams = DeepPartial<{
  name: string;
  legalName: string;
  url: string;
  maxShipTime: number;
  maxReturnRate: number;
  logoId: string;
  auth: CompanyAuthUpdate;
  gettingStarted: CompanyGettingStarted;
  gateway: string;
  ediData: CompanyEDIData;
  locations: Address[];
  privacy: CompanyPrivacy;
  contactEmail: string;
  sftp: CompanySFTPSettings;
  merchandisingSettings: MerchandisingSettings;
  commerceType: CommerceType;
  flags: CompanyFlagsUpdate;
  paymentTerms: PaymentTerms;
  shippingSettings: ShippingSettings;
  returnsSettings: CompanyReturnsSettings;
  enableShippingLinesShopify: boolean;
}>;

export type UpdateCompanyResponse = CompanyResponse;

export const updateCompany = (
  companyId: string,
  params: UpdateCompanyParams
): Promise<UpdateCompanyResponse> => patchRequest(`/companies/${companyId}`, params);

export interface CreateCompanyParams {
  company: {
    name?: string;
    companyUrl: string;
    source?: string;
    storeUrl?: string;
    commerceType: CommerceType;
    platform: Platform;
    temporaryTokenId?: string;
  };
}

interface CreateCompanyResponse {
  message: string;
  companyObjectId: string;
}

export const createCompany = (params: CreateCompanyParams): Promise<CreateCompanyResponse> =>
  postRequest('/companies/', params);

export const getCompanyLocations = (companyId: string): Promise<Response<Address[]>> =>
  getRequest(`/companies/${companyId}/locations`);

type DeleteLocationFromCompanyResponse = {};

export const deleteLocationFromCompany = (
  companyId: string,
  locationId: string
): Promise<DeleteLocationFromCompanyResponse> =>
  deleteRequest(`/companies/${companyId}/locations/${locationId}`);

export interface CreateBillingPortalResponse {
  redirectURL: string;
}

export const createBillingPortal = (): Promise<CreateBillingPortalResponse> =>
  postRequest('/companies/billing');

interface CreateUSBillingPortalResponse {
  redirectURL: string;
}

export const createUSBillingPortal = (): Promise<CreateUSBillingPortalResponse> =>
  postRequest('/companies/billingus');

interface UpdateSftpCredsParams {
  host?: string;
  port?: number;
  username: string;
  password: string;
  hostKey?: string;
  uploadDirectory?: string;
  downloadDirectory?: string;
  archiveMethod: string;
  archiveDirectory?: string;
}

type UpdateSftpCredsResponse = Response<{}>;

export const updateSFTPCreds = (
  companyId: string,
  params: UpdateSftpCredsParams
): Promise<UpdateSftpCredsResponse> => patchRequest(`/companies/${companyId}/auth/sftp`, params);

type RemoveUserFromCompanyResponse = Response<CompanyResponse>;

export const removeUserFromCompany = (
  companyId: string,
  userId: string
): Promise<RemoveUserFromCompanyResponse> =>
  deleteRequest(`/companies/${companyId}/users/${userId}`);

type MakeUserOwnerOfCompanyResponse = Response<CompanyResponse>;

export const makeUserOwnerOfCompany = (
  companyId: string,
  userId: string
): Promise<MakeUserOwnerOfCompanyResponse> =>
  putRequest(`/companies/${companyId}/users/${userId}/owner`);

export const updateShopify = (companyId: string, settings: CompanyShopifySettings): Promise<any> =>
  patchRequest(`/companies/${companyId}/shopify`, settings);
