import Grid from '@react-css/grid';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useAlertQueue from 'hooks/useAlertQueue';
import { useForm } from 'react-hook-form';
import { selectCompany } from 'store/selectors/me/company';
import { updateShopifySettings } from 'store/slices/company';
import SecondaryButton from 'storybook/stories/molecules/Button/SecondaryButton';
import Toggle from 'storybook/stories/molecules/Toggle';
import styled from 'styled-components';
import { CommerceType } from 'types/models/company';

interface EditShareCustomerSettingsFormProps {
  shareCustomerPhoneNumber: boolean;
  shareCustomerEmailAddress: boolean;
}

const StyledGrid = styled(Grid)`
  margin-top: 16px;
  gap: 8px;
`;

const StyledField = styled.div`
  margin: 16px 0;
`;

const EditShareCustomerSettings = () => {
  const dispatch = useDispatch();
  const { addSuccessAlert, addErrorAlert } = useAlertQueue();

  const company = useSelector(selectCompany);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    watch,
  } = useForm<EditShareCustomerSettingsFormProps>({
    defaultValues: {
      shareCustomerPhoneNumber: company?.shopify?.shareCustomerPhoneNumber,
      shareCustomerEmailAddress: company?.shopify?.shareCustomerEmailAddress,
    },
  });

  const watchShareCustomerPhoneNumber = watch('shareCustomerPhoneNumber');
  const watchShareCustomerEmailAddress = watch('shareCustomerEmailAddress');

  useEffect(() => reset(), [reset]);

  const onSubmit = handleSubmit((data: EditShareCustomerSettingsFormProps) => {
    dispatch(updateShopifySettings(data))
      .then(() => addSuccessAlert('Settings updated successfully', ''))
      .catch(() => addErrorAlert('Failed to update settings', ''));
  });

  // Don't show for sellers or companies who have uninstalled Shopify
  if (company?.commerceType !== CommerceType.Buyer) return null;
  if (!company?.shopify) return null;

  return (
    <StyledGrid>
      <form onSubmit={onSubmit}>
        <h4>Share Customer Information from Shopify</h4>
        <StyledField>
          <div className="input-group input-group-sm">
            <Toggle
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...register('shareCustomerPhoneNumber')}
              description={
                watchShareCustomerPhoneNumber
                  ? "Include the customer's phone number when placing orders with your sellers."
                  : "Do not include the customer's phone number when placing orders with your sellers."
              }
            >
              {watchShareCustomerPhoneNumber
                ? 'Share customer phone number'
                : 'Do not share customer phone number'}
            </Toggle>
          </div>
          {errors?.shareCustomerPhoneNumber ? (
            <div className="invalid-feedback d-block">
              {errors.shareCustomerPhoneNumber.message}
            </div>
          ) : null}
        </StyledField>
        <StyledField>
          <div className="input-group input-group-sm">
            <Toggle
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...register('shareCustomerEmailAddress')}
              description={
                watchShareCustomerEmailAddress
                  ? "Include the customer's email address when placing orders with your sellers."
                  : "Do not include the customer's email address when placing orders with your sellers."
              }
            >
              {watchShareCustomerEmailAddress
                ? 'Share customer email address'
                : 'Do not share customer email address'}
            </Toggle>
          </div>
          {errors?.shareCustomerEmailAddress ? (
            <div className="invalid-feedback d-block">
              {errors.shareCustomerEmailAddress.message}
            </div>
          ) : null}
        </StyledField>
        <SecondaryButton size="small" type="submit">
          Save
        </SecondaryButton>
      </form>
    </StyledGrid>
  );
};

export default EditShareCustomerSettings;
