// Regex for URL Fields
// From: https://stackoverflow.com/a/3809435
// Modified to include up to 63 characters in the TLD
// As per: https://developer.mozilla.org/en-US/docs/Learn/Common_questions/What_is_a_domain_name
export const URL_FIELD_REGEX =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,63}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const SHOPIFY_STORE_URL_REGEX =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.myshopify\.com\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const BIGCOMMERCE_STORE_URL_REGEX =
  /https:\/\/(store-[a-zA-Z0-9]{1,256}\.bigcommerce|[a-zA-Z0-9-]{1,256}\.mybigcommerce)\.com([\/a-zA-Z0-9()@:%_+.~#?&=-]*)/; /* eslint-disable-line */

export function toUpper(str: string) {
  if (!str) {
    return '';
  }

  return str.toUpperCase();
}

export function capitalizeFirstChar(str: string) {
  if (!str) {
    return str;
  }

  if (str.length === 0) {
    return str;
  }
  if (str.length === 1) {
    return toUpper(str);
  }
  return toUpper(str.substring(0, 1)) + str.substring(1, str.length);
}

// Snagged from https://stackoverflow.com/a/7888303
export const separateCamelCase = (str: string) => str.split(/(?=[A-Z])/).join(' ');

export const toProperCase = (str: string) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getDomainFromUrl = (url: string) => {
  try {
    if (url.match(/^(https?:\/\/)/)) {
      return new URL(url).hostname;
    }

    return new URL(`http://${url}`).hostname;
  } catch (e) {
    return url;
  }
};
